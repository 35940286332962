<template>
  <page-header-wrapper>
    <template v-slot:content>
      <div class="page-header-content">
        <div class="avatar">
          <a-avatar size="large" :src="avatar"/>
        </div>
        <div class="content">
          <div class="content-title">
            {{ timeFix }}，{{ nickname }}<span class="welcome-text"></span>
          </div>
          <div> {{ postGroup }} | {{ user.dept && user.dept.deptName }}</div>
        </div>
      </div>

      <a-card>
        <div>
          <a-row>
            <a-col :span="8" style="background: rgb(0, 0, 255, 0.1); padding: 20px">
              <div class="grid-content bg-purple">
<!--                <div class="left" >-->
<!--                  <a-icon type="money-collect" style="font-size: 2.5rem;color: blue"/>-->
<!--                </div>-->
                <div class="left">
                  <p class="title">总交易额(今日)</p>
                  <p class="title color margin-b-10">¥ {{ (dataList.today.payAmount) }}</p>
                  <div :style="{color:dataList.yesterday.payAmount>dataList.today.payAmount?'red':'green'}">
                    <span>昨日同时段{{ (dataList.yesterday.payAmount) }}元</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.payAmount)>Number(dataList.today.payAmount)"/>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.payAmount)<=Number(dataList.today.payAmount)"/>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col :span="8" style="background: rgb(34, 139, 34, 0.1); margin: 0 14px; padding: 20px">
              <div class="grid-content bg-purple-light">
<!--                <div class="left ">-->
<!--                  <a-icon type="money-collect" style="font-size: 2.5rem;color: red"/>-->
<!--                </div>-->
                <div class="left">
                  <p class="title">退款金额(今日)</p>
                  <p class="title color margin-b-10">¥ {{ (dataList.today.refundAmount) }}</p>
                  <div :style="{color:Number(dataList.yesterday.refundAmount)>Number(dataList.today.refundAmount)?'red':'green'}">
                    <span>昨日同时段{{ (dataList.yesterday.refundAmount) }}元</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.refundAmount)>Number(dataList.today.refundAmount)"></a-icon>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.refundAmount)<Number(dataList.today.refundAmount)"></a-icon>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col :span="7" style="background: rgb(0, 0, 255, 0.1); padding: 20px;margin-right: 10px;">
              <div class="grid-content bg-purple">
<!--                <div class="left cornflowerblue">-->
<!--                  <a-icon type="money-collect" style="font-size: 2.5rem;color: green"/>-->
<!--                </div>-->
                <div class="left">
                  <p class="title">客单价(今日)</p>
                  <p class="title color margin-b-10">¥ {{ (dataList.today.customerPrice) }}</p>
                  <div
                    :style="{color:Number(dataList.yesterday.customerPrice)>Number(dataList.today.customerPrice)?'red':'green'}">
                    <span>昨日同时段{{ (dataList.yesterday.customerPrice) }}元</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.customerPrice)>Number(dataList.today.customerPrice)"></a-icon>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.customerPrice)<Number(dataList.today.customerPrice)"></a-icon>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
          <a-row style="margin-top: 20px">
            <a-col :span="6" style="background: rgb(106, 90, 205, 0.1); padding: 20px">
              <div class="grid-content bg-purple" @click.prevent="handleLink('/order/order')">
<!--                <div class="left cornflowerblue">-->
<!--                  <a-icon type="account-book" style="font-size: 2.5rem;color: deepskyblue"/>-->
<!--                </div>-->
                <div class="left">
                  <p class="title">总订单数(今日)</p>
                  <p class="title color margin-b-10">{{ dataList.today.orderCount }}</p>
                  <div
                    :style="{color:Number(dataList.yesterday.orderCount)>Number(dataList.today.orderCount)?'red':'green'}">
                    <span>昨日同时段{{ dataList.yesterday.orderCount }}单</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.orderCount)>Number(dataList.today.orderCount)"></a-icon>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.orderCount)<Number(dataList.today.orderCount)"></a-icon>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col :span="6" style="background: rgb(0, 0, 255, 0.1); margin: 0 10px; padding: 20px">
              <div class="grid-content bg-purple-light" @click.prevent="handleLink('/order/refund')">
<!--                <div class="left cornflowerblue">-->
<!--                  <a-icon type="account-book" style="font-size: 2.5rem;color: red"/>-->
<!--                </div>-->
                <div class="left">
                  <p class="title">退款单数(今日)</p>
                  <p class="title color margin-b-10">{{ dataList.today.refundCount }}</p>
                  <div :style="{color:Number(dataList.yesterday.refundCount)>Number(dataList.today.refundCount)?'red':'green'}">
                    <span>昨日同时段{{ dataList.yesterday.refundCount }}单</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.refundCount)>Number(dataList.today.refundCount)"></a-icon>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.refundCount)<Number(dataList.today.refundCount)"></a-icon>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col :span="5" style="background: rgb(138, 43, 266, 0.1); margin: 0 10px;  padding: 20px">
              <div class="grid-content bg-purple" @click.prevent="handleLink('/user/tbUser')">
<!--                <div class="left cornflowerblue">-->
<!--                  <a-icon type="user-add" style="font-size: 2.5rem;color: #bae7ff"/>-->
<!--                </div>-->
                <div class="left">
                  <p class="title">今日新增用户数</p>
                  <p class="title color margin-b-10">{{ dataList.today.userCount || 0 }}</p>
                  <div
                    :style="{color:Number(dataList.yesterday.userCount)>Number(dataList.userCount)?'red':'green'}">
                    <span>昨日同时段{{ dataList.yesterday.userCount || 0 }}个</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.userCount)>Number(dataList.today.userCount)"></a-icon>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.userCount)<Number(dataList.today.userCount)"></a-icon>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col :span="5" style="background: rgb(138, 43, 266, 0.1); padding: 20px">
              <div class="grid-content bg-purple">
<!--                <div class="left cornflowerblue">-->
<!--                  <a-icon type="usergroup-add" style="font-size: 2.5rem;color: #bae7ff"/>-->
<!--                </div>-->
                <div class="left">
                  <p class="title">今日新增邀请用户数</p>
                  <p class="title color margin-b-10">{{ dataList.today.inviteUserCount || 0 }}</p>
                  <div
                    :style="{color:Number(dataList.yesterday.inviteUserCount)>Number(dataList.inviteUserCount)?'red':'green'}">
                    <span>昨日同时段{{ dataList.yesterday.inviteUserCount || 0 }}个</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.inviteUserCount)>Number(dataList.today.inviteUserCount)"></a-icon>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.inviteUserCount)<Number(dataList.today.inviteUserCount)"></a-icon>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
          <a-row style="margin-top: 20px">
            <a-col :span="7" style="background: rgb(255, 192, 203, 0.1); padding: 20px">
              <div class="grid-content bg-purple">
<!--                <div class="left cornflowerblue">-->
<!--                  <a-icon type="money-collect" style="font-size: 2.5rem;color: red"/>-->
<!--                </div>-->
                <div class="left">
                  <p class="title">商品金额(今日)</p>
                  <p class="title color margin-b-10">¥ {{ (dataList.today.goodsAmount) }}</p>
                  <!-- <p class="title color">¥ 200</p> -->
                  <div
                    :style="{color:Number(dataList.yesterday.goodsAmount)>Number(dataList.today.goodsAmount)?'red':'green'}">
                    <span>昨日同时段{{ (dataList.yesterday.goodsAmount) }}元</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.goodsAmount)>Number(dataList.today.goodsAmount)"></a-icon>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.goodsAmount)<Number(dataList.today.goodsAmount)"></a-icon>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col :span="7" style="background: rgb(138, 43, 266, 0.1); margin: 0 14px; padding: 20px">
              <div class="grid-content bg-purple-light">
<!--                <div class="left cornflowerblue">-->
<!--                  <a-icon type="money-collect" style="font-size: 2.5rem;color: orange"/>-->
<!--                </div>-->
                <div class="left">
                  <p class="title">运费(今日)</p>
                  <p class="title color margin-b-10">¥ {{ (dataList.today.shippingAmount) }}</p>
                  <div :style="{color:Number(dataList.yesterday.shippingAmount)>Number(dataList.today.shippingAmount)?'red':'green'}">
                    <span>昨日同时段{{ (dataList.yesterday.shippingAmount) }}元</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.shippingAmount)>Number(dataList.today.shippingAmount)"></a-icon>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.shippingAmount)<Number(dataList.today.shippingAmount)"></a-icon>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>


<!--          <a-row style="margin-top: 20px">
            <a-col :span="7" style="background: rgb(255, 192, 203, 0.1);  padding: 20px">
              <div class="grid-content bg-purple">
                <div class="left cornflowerblue">
                  <a-icon type="read" style="font-size: 2.5rem;color: red"/>
                </div>
                <div class="left">
                  <p class="title">广告展示总数</p>
                  <p class="title color margin-b-10">{{ advert.showNum }}</p>
                </div>
              </div>
            </a-col>
            <a-col :span="7" style="background: rgb(255, 192, 203, 0.1); margin: 0 14px;  padding: 20px">
              <div class="grid-content bg-purple">
                <div class="left cornflowerblue">
                  <a-icon type="link" style="font-size: 2.5rem;color: red"/>
                </div>
                <div class="left">
                  <p class="title">广告跳转总次数</p>
                  <p class="title color margin-b-10">{{ advert.jumpNum }}</p>
                </div>
              </div>
            </a-col>
            <a-col :span="7" style="background: rgb(255, 192, 203, 0.1); margin: 0 14px;  padding: 20px">
              <div class="grid-content bg-purple">
                <div class="left cornflowerblue">
                  <a-icon type="info" style="font-size: 2.5rem;color: red"/>
                </div>
                <div class="left">
                  <p class="title">广告点击总数</p>
                  <p class="title color margin-b-10">{{ advert.clickNum }}</p>
                </div>
              </div>
            </a-col>
          </a-row>
          <a-row style="margin-top: 20px">
            <a-col :span="6" style="background: rgb(0, 0, 255, 0.1);; padding: 20px">
              <div class="grid-content bg-purple">
                <div class="left cornflowerblue">
                  <a-icon type="money-collect" style="font-size: 2.5rem;color: red"/>
                </div>
                <div class="left">
                  <p class="title">商品分享数</p>
                  <p class="title color margin-b-10">{{ goodsShare.shareNum }}</p>
                </div>
              </div>
            </a-col>
            <a-col :span="6" style="background: rgb(255, 192, 203, 0.1); margin: 0 14px;  padding: 20px">
              <div class="grid-content bg-gray">
                <div class="left cornflowerblue">
                  <a-icon type="money-collect" style="font-size: 2.5rem;color: red"/>
                </div>
                <div class="left">
                  <p class="title">商品点击数</p>
                  <p class="title color margin-b-10">{{ goodsShare.clicksNum }}</p>
                </div>
              </div>
            </a-col>
            <a-col :span="5" style="background: rgb(0, 0, 255, 0.1); margin: 0 14px;  padding: 20px">
              <div class="grid-content bg-gray">
                <div class="left cornflowerblue">
                  <a-icon type="money-collect" style="font-size: 2.5rem;color: red"/>
                </div>
                <div class="left">
                  <p class="title">商品收藏数</p>
                  <p class="title color margin-b-10">{{ goodsShare.collectionNum }}</p>
                </div>
              </div>
            </a-col>
          </a-row>-->

        </div>

      </a-card>
      <a-card>
        <a-row>
          <a-col :span="12">
            <div style="padding: 5px">
              <div class="right two" >
                <span :class="isOrderSelect === 'days'?'isActive': ''" @click="handelOrderClick('days')">按天</span>
                <span :class="isOrderSelect === 'months'?'isActive': ''" @click="handelOrderClick('months')">按月</span>
                <span :class="isOrderSelect === 'years'?'isActive': ''" @click="handelOrderClick('years')" style="margin-right:10px;">按年</span>
              </div>
              <a-range-picker valueFormat="YYYY-MM-DD"  @change="orderAmountDateChange"  size="large"/>
            </div>
            <div id="orderAmountChart" style="height: 400px"></div>
          </a-col>

          <a-col :span="12">
            <div style="padding: 5px">
              <div class="right two" >
                <span :class="isOrderCountSelect === 'days'?'isActive': ''" @click="handelOrderCountClick('days')">按天</span>
                <span :class="isOrderCountSelect === 'months'?'isActive': ''" @click="handelOrderCountClick('months')">按月</span>
                <span :class="isOrderCountSelect === 'years'?'isActive': ''" @click="handelOrderCountClick('years')" style="margin-right:10px;">按年</span>
              </div>
              <a-range-picker  valueFormat="YYYY-MM-DD"  @change="orderCountDateChange"  size="large"/>
            </div>
            <div id="orderCountChart" style="height: 400px"></div>
          </a-col>

        </a-row>
      </a-card>

    </template>


  </page-header-wrapper>
</template>

<script>
import {timeFix} from '@/utils/util'
import * as echarts from 'echarts'
import {mapGetters} from 'vuex'
import {PageHeaderWrapper} from '@/components/ProLayout'
import {getUserProfile} from '@/api/system/user'
import {goodsResult, homeResult,advertResult} from '@/api/statistics/home'
import {orderAmountChart} from "@/api/statistics/order";

export default {
  name: 'IndexWelcome',
  components: {
    PageHeaderWrapper
  },
  data() {
    return {
      dataList: {
        today:{},
        yesterday:{},
      }, //首页看板
      advert: {}, //广告
      goodsShare: {}, //商品
      timeFix: timeFix(),
      // 用户信息
      user: {
        dept: {
          deptName: ''
        }
      },

      orderAmountParam: {
        chartType: 'days',
        dateRange: [],
        startTime: undefined,
        endTime:undefined,

      },
      orderCountParam: {
        chartType: 'days',
        dateRange: [],
        startTime: undefined,
        endTime:undefined,

      },
      /*销售数据选择*/
      isOrderSelect: 'days',
      isOrderCountSelect: 'days',
      roleGroup: {},
      postGroup: {},
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'nickname'
    ])
  },
  created() {
    this.getUser()
    this.getHomeStatistics();
    this.getAdvertResult();
    this.getGoodResult();
  },
  mounted() {
    this.drawOrderAmountChart()
    this.drawOrderCountChart()

  },
  methods: {
    // 获取用户信息
    getUser() {
      getUserProfile().then(response => {
        this.user = response.data
        this.roleGroup = response.roleGroup
        this.postGroup = response.postGroup
      })
    },
    getHomeStatistics() {
      homeResult().then(response => {
        this.dataList = response.data
      });

    },
    getAdvertResult() {
      advertResult().then(response => {
        this.advert = response.data
      });
    },
    getGoodResult() {
      goodsResult().then(response => {
        this.goodsShare = response.data
      });
    },

    orderAmountDateChange(val) {
      this.orderAmountParam.startTime = val[0] + ' 00:00:00';
      this.orderAmountParam.endTime = val[1] + ' 23:59:59';
      this.drawOrderAmountChart()
    },
    orderCountDateChange(val) {
      this.orderCountParam.startTime = val[0] + ' 00:00:00';
      this.orderCountParam.endTime = val[1]+ ' 23:59:59';
      this.drawOrderCountChart()
    },
    handelOrderClick(val) {
      this.isOrderSelect = val;
      this.orderAmountParam.chartType = val;
      this.drawOrderAmountChart();
    },
    handelOrderCountClick(val) {
      this.isOrderCountSelect = val;
      this.orderCountParam.chartType = val;
      this.drawOrderCountChart();
    },

    drawOrderAmountChart() {
      // // 添加
      var chartDom = document.getElementById("orderAmountChart");
      var myChart = echarts.init(chartDom);
      var option;
      orderAmountChart(this.orderAmountParam).then((res) => {
        if (res.code === "20001") {
          let yaxis = res.data.yyAxis;
          let xAxis = res.data.xxAxis;
          option = {
            title: {
              text: '订单销售金额趋势图'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            legend: {
              data: ['订单销售额', '运费金额','退款金额']
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: xAxis
              }
            ],
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '运费金额',
                type: 'line',
                stack: 'shippingAmount',
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      }
                      return params.value;
                    }
                  }
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(211,89,13,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(197,105,67,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                lineStyle: {
                  color: '#ee8e22' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#ee8e22' // 设置标点的颜色为蓝色
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.shippingPayAmountList
              },
              {
                name: '退款金额',
                type: 'line',
                stack: 'refundAmount',
                lineStyle: {
                  color: '#f30202' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#f30202' // 设置标点的颜色为蓝色
                  }
                },
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      }
                      return params.value;
                    }
                  }
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(218,119,119,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(239,5,5,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.refundAmountList
              },
              {
                name: '订单销售额',
                type: 'line',
                stack: 'payAmount',
                lineStyle: {
                  color: '#46f302' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#46f302' // 设置标点的颜色为蓝色
                  }
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(45,255,0,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(123,203,96,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      }
                      return params.value;
                    }
                  },
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.payAmountList
              },
            ]
          };


          option && myChart.setOption(option);
        }
      });
    },

    drawOrderCountChart() {
      // // 添加
      var chartDom = document.getElementById("orderCountChart");
      var myChart = echarts.init(chartDom);
      var option;
      orderAmountChart(this.orderCountParam).then((res) => {
        if (res.code === "20001") {
          let yaxis = res.data.yyAxis;
          let xAxis = res.data.xxAxis;
          option = {
            title: {
              text: '订单量趋势图'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            legend: {
              data: ['订单量', '退款量']
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: xAxis
              }
            ],
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '退款量',
                type: 'line',
                lineStyle: {
                  color: '#f30202' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#f30202' // 设置标点的颜色为蓝色
                  }
                },
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      } else {
                        return params.value;
                      }
                    }
                  }
                },
                stack: 'refundCount',
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(238,177,184,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(255,0,0,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.refundCountList
              },
              {
                name: '订单量',
                type: 'line',
                stack: 'orderCount',
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(45,255,0,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(123,203,96,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                lineStyle: {
                  color: '#46f302' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#46f302' // 设置标点的颜色为蓝色
                  }
                },
                label: {
                  show: true,
                  position: 'top'
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.orderCountList
              },
            ]
          };


          option && myChart.setOption(option);
        }
      });
    },

    onSponsorTabChange(key, type) {
      this[type] = key
    }
  }
}
</script>

<style lang="less" scoped>
@import "../index.less";

blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #dfe2e5;
}

.project-list {

  .card-title {
    font-size: 0;

    a {
      color: rgba(0, 0, 0, 0.85);
      margin-left: 12px;
      line-height: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;

      &:hover {
        color: #1890ff;
      }
    }
  }

  .card-description {
    color: rgba(0, 0, 0, 0.45);
    height: 66px;
    line-height: 22px;
    overflow: hidden;
  }

  .project-item {
    display: flex;
    margin-top: 8px;
    overflow: hidden;
    font-size: 12px;
    height: 20px;
    line-height: 20px;

    a {
      color: rgba(0, 0, 0, 0.45);
      display: inline-block;
      flex: 1 1 0;

      &:hover {
        color: #1890ff;
      }
    }

    .download {
      color: rgba(0, 0, 0, 0.25);
      flex: 0 0 auto;
      float: right;
    }
  }

  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
    height: 44px;
    line-height: 22px;
    overflow: hidden;
  }
}

.item-group {
  padding: 20px 0 8px 24px;
  font-size: 0;

  a {
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    font-size: 14px;
    margin-bottom: 13px;
    width: 25%;
  }
}

.mobile {

  .project-list {

    .project-card-grid {
      width: 100%;
    }
  }

  .more-info {
    border: 0;
    padding-top: 16px;
    margin: 16px 0 16px;
  }

  .headerContent .title .welcome-text {
    display: none;
  }
}

.home {
  blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
  }

  .col-item {
    margin-bottom: 20px;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  font-family: "open sans",
  "Helvetica Neue",
  Helvetica,
  Arial,
  sans-serif;
  font-size: 13px;
  color: #676a6c;
  overflow-x: hidden;

  ul {
    list-style-type: none;
  }

  h4 {
    margin-top: 0px;
  }

  h2 {
    margin-top: 10px;
    font-size: 26px;
    font-weight: 100;
  }

  p {
    margin-top: 10px;

    b {
      font-weight: 700;
    }
  }

  .update-log {
    ol {
      display: block;
      list-style-type: decimal;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 40px;
    }
  }
}

.left {
  float: left;
}

.right {
  float: right;
}

.cleafix {
  clear: both;
  overflow: hidden;
  content: '';
}

::v-deep .el-icon-s-custom:before {
  font-size: 30px;
}

.cornflowerblue {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin-right: 10px;
}

::v-deep .el-table th {
  background: rgba(3, 146, 240, 1);
  color: #fff;
}

::v-deep .el-table .warning-row {
  background: rgb(169, 109, 205, 0.1);
}

::v-deep .el-table .success-row {
  background: rgba(108, 182, 201, 0.1);
}

::v-deep .el-table__body-wrapper {
  height: 324px;
  overflow-y: auto;
}

.two1 {
  // margin-left: 125px;
  margin-bottom: 20px;

  span {
    display: inline-block;
    line-height: 40px;
    width: 60px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #999999;
    // color: #1890ff;
  }

  span:nth-child(1) {
    border-radius: 6px 0 0 6px;
    border-right: none;

  }

  span:nth-child(2) {
    border-radius: 0;
    border-right: none;

  }

  span:nth-child(3) {
    border-radius: 0;
    border-right: none;

  }

  span:nth-child(4) {
    border-radius: 0 6px 6px 0;
    position: relative;
  }

  span:nth-child(4) .el-date-editor {
    position: absolute; //绝对定位
    top: 0;
    left: 0;
    opacity: 0; //设置完全透明
  }
}

.two {
  margin-left: 10px;
  margin-right: 125px;

  span {
    display: inline-block;
    line-height: 40px;
    width: 60px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #999999;
    // color: #1890ff;
  }

  span:nth-child(1) {
    border-radius: 6px 0 0 6px;
    border-right: none;

  }

  span:nth-child(2) {
    border-radius: 0;
    border-right: none;

  }

  span:nth-child(3) {
    border-radius: 0;
    border-right: none;

  }

  span:nth-child(4) {
    border-radius: 0 6px 6px 0;
    position: relative;
  }

  span:nth-child(4) .el-date-editor {
    position: absolute; //绝对定位
    top: 0;
    left: 0;
    opacity: 0; //设置完全透明
  }
}

.isActive {
  background: #1890ff;
  color: #ffffff !important;
  border: 1px solid #1890ff !important;

  // color: #fff;
}

::v-deep .el-range-input {
  // width: 84px;
}

::v-deep .el-input__inner {
  // width: auto !important;
}

.title {
  font-weight: bold;
  font-size: 16px;
}

.color {
  color: red;
  font-size: 18px;
}

.margin-b-10 {
  margin-bottom: 10px !important;
}
.cleafix {
  clear: both;
  overflow: hidden;
  content: '';
}
</style>
